
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');


h1{
  font-family: sans-serif;
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
  /* font-family: "Comic Neue", cursive; */
  /* font-weight: 500; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




.swiper-pagination .swiper-pagination-bullet{
  background-color: rgb(255, 136, 0);
  border: 3px solid rgb(255, 255, 255);
  opacity: 1;
  width: 15px;
padding: 7px;
border-radius: 50%;
  height: 15px;
}
