@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');

    .div_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 80vh;
      padding: 0 1rem;
      /* background-color: aqua; */
      /* justify-content: center; */
      align-items: center;
      width: 100%;
    }

    .div_container h2 {
      font-size: 30px;
    }
    .map_container {
      height: 400px;
      width: 50%;
      z-index: 0;
    }

   

    .div_container >p {
      font-size: 16px;
      text-align: center;
      color: grey;
    }
   
    .inp_label {
      margin-bottom: 5px;
    }
    
    .inp_txt input {
      width: 25rem;
      padding: 10px;
      outline: none;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    @media screen and (max-width: 768px) {
      .inp_txt input {
        width: 100%;
        padding: 10px;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
    .suggestionsList {
      list-style: none;
      padding: 0;
      width: 25rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      max-height: 200px;
      overflow-y: auto;
    }
    
    .suggestionsList li {
      padding: 10px;
      cursor: pointer;
      background-color: white;
    }
    
    .suggestionsList li:hover {
      background-color: #f0f0f0;
    }

    .prev {
      width: 120px;
      height: 40px;
      background-color: rgb(99, 180, 250);
      padding: 8px;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    .nxt {
      width: 120px;
      height: 40px;
      background-color: rgb(192, 192, 11);
      padding: 8px;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    .prev:hover {
      background-color: gray;
    }

    .nxt:hover {
      background-color: gray;
    }

    .locationBtn {
      display: flex;
      gap: 1rem;
      align-items: center;
      background-color: #4CAF50;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;
    }
    
    .locationBtn:hover {
      background-color: #45a049;
    }
    .yes_btn{
      background-color: #4CAF50;
      color: #fff;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }