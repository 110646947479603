.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.div_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #fff;
  padding: 20px;
  width: 100%; /* Changed to 100% to make it responsive */
  max-width: 50rem; /* Added max-width to maintain a maximum size */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow-x: auto; /* Ensure horizontal overflow is handled */
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.note {
  background-color: #fff4d6;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
}

.btn {
  justify-content: center;
  display: flex;
  gap: 1rem;
}

.prev {
  width: 120px;
  height: 40px;
  background-color: rgb(99, 180, 250);
  padding: 8px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.nxt {
  width: 120px;
  height: 40px;
  background-color: rgb(192, 192, 11);
  padding: 8px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.prev:hover {
  background-color: gray;
}

.nxt:hover {
  background-color: gray;
}

.scheduleTable {
  width: 100%;
  border-collapse: collapse;
}

.scheduleTable th, .scheduleTable td {
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 8px;
  text-align: center;
}

.scheduleTable th {
  background-color: #f4f4f4;
}

.icon {
  cursor: pointer;
  font-size: 1.5rem;
}
