@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');


.form_container {
    width: 100%;
    padding:1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}
.form_container input{
    outline: none;
}
.form_container select{
    background-color: none;
    height: 35px;
    outline: none;
    border: 1px solid gray;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.prev {
    width: 120px;
    height: 40px;
    background-color: rgb(99, 180, 250);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.nxt {
    width: 120px;
    height: 40px;
    background-color: rgb(192, 192, 11);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.prev:hover {
    background-color: gray;
}

.nxt:hover {
    background-color: gray;
}