
      .div_container {
        display: flex;
        gap: 1rem;
        height: 80vh;
        padding: 0 1rem;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

     .div_container h2 {
        font-size: 28px;
        font-weight: bold;
      }
      
      .subheading {
        color: grey;
        text-align: center;
        font-weight: bold;
      }

      .label_wrap {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }

      .input_wrap {
        display: flex;
        gap: 1rem;
      }
      @media screen and (max-width: 768px) {
        .input_wrap{
          flex-direction: column;
        }
      }

      .input {
        width: 300px;
        height: 50px;
        border: 1px solid rgb(238, 233, 233);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px;
        padding-right: 40px;
      }

      .input label {
        font-size: 16px;
      }

      /* .input input[type="radio"] {
        transform: scale(2);
      } */

      /* .input input[type="radio"] {
        accent-color: rgb(255, 230, 0);
      } */

      .btn {
        margin-top: 20px;
        margin-left: 60px;
      }

      .prev {
        width: 120px;
        height: 40px;
        background-color: rgb(99, 180, 250);
        padding: 8px;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
        text-decoration: none;
      }

      .nxt {
        width: 120px;
        height: 40px;
        background-color: rgb(192, 192, 11);
        padding: 8px;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        font-weight: bold;
        margin-left: 10px;
      }

      .prev:hover {
        background-color: gray;
      }

      .nxt:hover {
        background-color: gray;
      }