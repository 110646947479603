@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');

.mainContainer{
    display: flex;
    justify-content: center;
}

.div_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 100px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.heading>p{
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
}
.subheading{
    text-align: center;
    color: rgb(75, 71, 71);
    font-size: 17px;
}
.notes >p{
    text-align: center;
    color: rgb(75, 71, 71);
    font-size: 18px;
    font-weight: bold;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

textarea {
    width: 100%;
    max-width: 650px;
    height: 200px;
    border: 1px solid rgb(241, 238, 238);
    outline: none;
    font-size: 18px;
    padding: 10px;
    color: #333;
}


.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.prev,
.nxt {
    width: 120px;
    height: 40px;
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.prev {
    background-color: rgb(99, 180, 250);
}

.nxt {
    background-color: rgb(192, 192, 11);
}

.prev:hover {
    background-color: gray;
}

.nxt:hover {
    background-color: gray;
}