.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.prev {
    width: 120px;
    height: 40px;
    background-color: rgb(99, 180, 250);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.nxt {
    width: 120px;
    height: 40px;
    background-color: rgb(192, 192, 11);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.prev:hover {
    background-color: gray;
}

.nxt:hover {
    background-color: gray;
}