@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');

.mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.div_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
}

.options {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
@media screen and (max-width:425px) {
    .options{
        flex-direction: column;
    }
    
}

.option {
    flex: 1;
    border: 1px solid #f5f3f3;
    border-radius: 10px;
    padding: 15px;
    transition: background-color 0.3s ease;
    height: auto;
}
.option span{
    font-size: 18px;
    font-weight: 600;
}

.option input {
    margin-bottom: 30px;
}

.option p {
    color: rgb(34, 29, 29);
    font-size: 16px;
}

input[type="radio"] {
    transform: scale(2);
}

input[type="radio"] {
    accent-color: rgb(255, 230, 0);
}

input[type="radio"]:checked+label {
    background-color: #f0f0f0;
    border-color: #999;
}

label {
    display: block;
    cursor: pointer;
}

h2 {
    margin-top: 0;
    font-size: 1.6em;
}

.price {
    font-weight: bold;
    margin-top: 10px;
    font-size: 1rem;
}

.notes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;
    font-size: 16px;
    color: #414040;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.prev {
    width: 120px;
    height: 40px;
    background-color: rgb(99, 180, 250);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.nxt {
    width: 120px;
    height: 40px;
    background-color: rgb(192, 192, 11);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.prev:hover {
    background-color: gray;
}

.nxt:hover {
    background-color: gray;
}