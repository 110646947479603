.div_container {
  display: flex;
  height: 100%;
  padding: 5rem 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.div_container>h2 {
  font-size: 30px;
  font-weight: bold;
}

.subheading {
  text-align: center;
}

.chefservice_div {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.chefservice_div1 {
  display: flex;
  flex-direction: column;
  border: 0.001rem solid rgb(247, 244, 244);
  border-radius: 5px;
  width: 20%;
  height: 150px;
  padding: 12px;
}

/* .chefservice_div1:hover {
  border: 1px solid rgb(240, 117, 35);
} */

@media screen and (max-width: 768px) {
  .div_container {
    padding: 0rem 1rem 1rem 1rem;
  }
  .chefservice_div{
    flex-direction: column;
    align-items: center;
  }
  .chefservice_div1{
    width: 90%;
  }
}

.chefservice_div h3 {
  font-size: 20px;
}

.chefservice_div p {
  font-size: 16px;
  color: gray;
}

.chefservice_div1 button {
  background-color: rgb(248, 225, 13);
  border-radius: 8px;
  width: 100px;
  height: 30px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
              0 2px 4px rgba(0, 0, 0, 0.06);
}

.chefservice_div1 button:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 
    0 4px 6px rgba(0, 0, 0, 0.1); 
     background-color: rgb(248, 225, 13);
    font-size: 14px;
}

.back {
  text-decoration: none;
  margin-left: 50px;
  font-size: 15px;
  color: rgb(65, 59, 59);
  font-weight: bold;
}