.mainDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    gap: 2rem;
}
.mainDiv>form{
    font-size: 20px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.prev {
    width: 120px;
    height: 40px;
    background-color: rgb(99, 180, 250);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .nxt {
    width: 120px;
    height: 40px;
    background-color: rgb(192, 192, 11);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .prev:hover {
    background-color: gray;
  }

  .nxt:hover {
    background-color: gray;
  }