@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');

.mainContainer{
    display: flex;
    justify-content: center;
}
.div_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.counter_group {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fafafa;
}

.counter_group:last-child {
    margin-bottom: 0;
}

.counter_group h3 {
    font-size: 1.2rem;
    margin: 0;
    text-align: left;
}

.subtext {
    font-size: 0.9rem;
    color: #777;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 15px;
}

.counter_controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.counter_controls_button {
    font-size: 1.5rem;
    padding: 5px 10px;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter_controls_button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.counter_controls span {
    font-size: 1.5rem;
    margin: 0 10px;
    min-width: 30px;
    text-align: center;
}

.btn {
   display: flex;
   justify-content: center;
   gap: 1rem;
}

.prev {
    width: 120px;
    height: 40px;
    background-color: rgb(99, 180, 250);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.nxt {
    width: 120px;
    height: 40px;
    background-color: rgb(192, 192, 11);
    padding: 8px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.prev:hover {
    background-color: gray;
}

.nxt:hover {
    background-color: gray;
}