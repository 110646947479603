@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');

    .div_container {
      display: flex;
      flex-direction: column;
      height: 80vh;
      justify-content: center;
      padding: 0 1rem;
      align-items: center;
      gap: 1rem;
    }
    .calendarContainer {
      text-align: center;
      background: #d0d0d0;
      width: 30%;
      padding: 1rem;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
    .calendarContainer > h2{
      font-size: 22px;
    }
    .dateRangePicker {
      font-size: 1.2rem;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      .calendarContainer {
        width: 100%;
      }
    }
    .btn {
      display: flex;
      gap: 1rem;
    }

    .prev {
      width: 120px;
      height: 40px;
      background-color: rgb(99, 180, 250);
      padding: 8px;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    .nxt {
      width: 120px;
      height: 40px;
      background-color: rgb(192, 192, 11);
      padding: 8px;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    .prev:hover {
      background-color: gray;
    }

    .nxt:hover {
      background-color: gray;
    }