.hireChefMianBtn {
    background-color: rgb(248, 225, 13);
    border-radius: 8px;
    width: 120px;
    height: 35px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
              0 2px 4px rgba(0, 0, 0, 0.06); 
  }
  
  .hireChefMianBtn:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 
    0 4px 6px rgba(0, 0, 0, 0.1); 
     background-color: rgb(248, 225, 13);
    font-size: 16px;
  }